import React, { Fragment } from 'react';

import { LinearProgress, Container, Avatar, Grid } from '@material-ui/core';

export default function AvpProgress() {
    const [completed, setCompleted] = React.useState(0);
    const [buffer, setBuffer] = React.useState(10);

    const progress = React.useRef(() => { });
    React.useEffect(() => {
        progress.current = () => {
            if (completed > 100) {
                setCompleted(0);
                setBuffer(10);
            } else {
                const diff = Math.random() * 10;
                const diff2 = Math.random() * 10;
                setCompleted(completed + diff);
                setBuffer(completed + diff + diff2);
            }
        };
    });

    React.useEffect(() => {
        function tick() {
            progress.current();
        }
        const timer = setInterval(tick, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <div className="d-flex flex-column align-items-center vh-100 justify-content-center text-center font-weight-bold font-size-lg">
            <Avatar style={{ borderRadius: 0, width: 110, height: 110, alignSelf: 'center' }} src={require(`../../../assets/images/stock-logos/avplogo.svg`)} />

            <LinearProgress value={25} className="m-3" style={{ width: 300 }}/>
        </div>
    );
}
