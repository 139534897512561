import BarChartIcon from '@material-ui/icons/BarChart';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ChatIcon from '@material-ui/icons/ChatOutlined';
import CodeIcon from '@material-ui/icons/Code';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import DashboardTwoToneIcon from '@material-ui/icons/DashboardTwoTone';
import GradeTwoTone from '@material-ui/icons/GradeTwoTone';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import MailIcon from '@material-ui/icons/MailOutlined';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import DvrIcon from '@material-ui/icons/Dvr';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import SendIcon from '@material-ui/icons/Send';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

var iconsMap = {
  BarChartIcon: BarChartIcon,
  CalendarTodayIcon: CalendarTodayIcon,
  ChatIcon: ChatIcon,
  CodeIcon: CodeIcon,
  DashboardIcon: DashboardIcon,
  ErrorIcon: ErrorIcon,
  FolderIcon: FolderIcon,
  DashboardTwoToneIcon: DashboardTwoToneIcon,
  GradeTwoTone: GradeTwoTone,
  ListAltIcon: ListAltIcon,
  LockOpenIcon: LockOpenIcon,
  MailIcon: MailIcon,
  PresentToAllIcon: PresentToAllIcon,
  PeopleIcon: PeopleIcon,
  PersonIcon: PersonIcon,
  ReceiptIcon: ReceiptIcon,
  SettingsIcon: SettingsIcon,
  ViewModuleIcon: ViewModuleIcon,
  MonetizationOnIcon: MonetizationOnIcon,
  DesktopWindowsIcon: DesktopWindowsIcon,
  DvrIcon: DvrIcon,
  AccountBalanceWalletIcon: AccountBalanceWalletIcon,
  SendIcon: SendIcon,
  TrendingDownIcon: TrendingDownIcon,
  AttachMoneyIcon: AttachMoneyIcon
};


// Accounting account
export default [
  {
    label: 'Menu',
    content: JSON.parse(
      `[
        {
          "label": "Transactions Reports",
          "icon":"ReceiptIcon",
          "content" :
          [
            {
            "label":"User's Report",
            "icon":"",
            "description":"N/A",
            "to":"/Reports/Transactions/VM"
            },
            {
              "label":"Lottery Sales Report",
              "icon":"",
              "description":"N/A",
              "to":"/Lottery/sales"
            },
            {
              "label":"3Wa Lottery Sales Report",
              "icon":"",
              "description":"N/A",
              "to":"/Lottery/combogame/sales"
            },
            {
              "label":"Pick2 Sales Report",
              "icon":"",
              "description":"N/A",
              "to":"/Lottery/pick2/salesreport"
            },
            {
              "label":"Ghana Game Sales Report",
              "icon":"",
              "description":"N/A",
              "to":"/ghanagame/sales-report"
            },
            {
              "label":"Mega Million Sales",
              "icon":"",
              "description":"N/A",
              "to":"/Megamillion/sales-report"
            },
          {
            "label":"Jackpot Sales Reports",
            "icon":"",
            "description":"N/A",
            "to":"/Jackpot/Reports"
          },
            {
              "label":"Raffle Sales Reports",
              "icon":"",
              "description":"N/A",
              "to":"/Raffle/Reports"
            }
          ]
        }
      ]`,
      (key, value) => {
        if (key === 'icon') {
          return iconsMap[value];
        } else {
          return value;
        }
      }
    )
  }
];
