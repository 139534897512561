import BarChartIcon from '@material-ui/icons/BarChart';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ChatIcon from '@material-ui/icons/ChatOutlined';
import { Phone,Search } from '@material-ui/icons';
import CodeIcon from '@material-ui/icons/Code';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import DashboardTwoToneIcon from '@material-ui/icons/DashboardTwoTone';
import GradeTwoTone from '@material-ui/icons/GradeTwoTone';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import HistoryIcon from '@material-ui/icons/History';
import MailIcon from '@material-ui/icons/MailOutlined';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import DvrIcon from '@material-ui/icons/Dvr';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import SendIcon from '@material-ui/icons/Send';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import StoreIcon from '@material-ui/icons/Store';
import BurstModeIcon from '@material-ui/icons/BurstMode';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import UpdateIcon from '@material-ui/icons/Update';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
var iconsMap = {
  BarChartIcon: BarChartIcon,
  CalendarTodayIcon: CalendarTodayIcon,
  Phone: Phone,
  ChatIcon: ChatIcon,
  HistoryIcon :HistoryIcon ,
  CodeIcon: CodeIcon,
  Search:Search,
  DashboardIcon: DashboardIcon,
  FolderIcon: FolderIcon,
  DashboardTwoToneIcon: DashboardTwoToneIcon,
  GradeTwoTone: GradeTwoTone,
  ListAltIcon: ListAltIcon,
  LockOpenIcon: LockOpenIcon,
  MailIcon: MailIcon,
  PresentToAllIcon: PresentToAllIcon,
  PeopleIcon: PeopleIcon,
  PersonIcon: PersonIcon,
  ReceiptIcon: ReceiptIcon,
  SettingsIcon: SettingsIcon,
  ViewModuleIcon: ViewModuleIcon,
  MonetizationOnIcon: MonetizationOnIcon,
  DesktopWindowsIcon: DesktopWindowsIcon,
  DvrIcon: DvrIcon,
  AccountBalanceWalletIcon: AccountBalanceWalletIcon,
  SendIcon: SendIcon,
  TrendingDownIcon: TrendingDownIcon,
  AttachMoneyIcon: AttachMoneyIcon,
  StoreIcon: StoreIcon,
  BurstModeIcon: BurstModeIcon,
  LiveHelpIcon: LiveHelpIcon,
  PhoneIphoneIcon: PhoneIphoneIcon,
  UpdateIcon: UpdateIcon,
  NotInterestedIcon: NotInterestedIcon,
  VerifiedUserIcon: VerifiedUserIcon
};

export default [
  {
    label: 'MENU',
    content: JSON.parse(
      `[

  {
    "label": "Lottery Settings",
    "icon": "SettingsIcon",
    "content":[
      {
        "label":"Results",
        "icon":"",
        "description":"N/A",
        "to":"/Lottery/results/entry"
      },
      {
        "label":"Results Validation",
        "icon":"",
        "description":"N/A",
        "to":"/Lottery/results/validation"
      },
      {
        "label":"Games",
        "icon":"",
        "description":"N/A",
        "to":"/settings/lottery/games"
      }
    ]
  },
  {
    "label": "3Wa Settings",
    "icon": "SettingsIcon",
    "content":[
      {
        "label":"3Wa Games",
        "icon":"",
        "description":"N/A",
        "to":"/settings/lottery/combolottery/games"
      },
      {
        "label":"3Wa Results Validation",
        "icon":"",
        "description":"N/A",
        "to":"/Lottery/combolottery/results/validation"
      }
    ]
  },
{
  "label": "Pick 2 Settings",
  "icon": "SettingsIcon",
  "content":[
    {
      "label":"Results",
      "icon":"",
      "description":"N/A",
      "to":"/Lottery/pick2/results/entry"
    },
    {
      "label":"Results Validation",
      "icon":"",
      "description":"N/A",
      "to":"/Lottery/pick2/results/validation"
    },
    {
      "label":"Pick 2 Games",
      "icon":"",
      "description":"N/A",
      "to":"/settings/lottery/pick2/games"
    }
  ]
},
{
  "label": "Ghana Game Settings",
  "icon": "SettingsIcon",
  "content":[
    {
      "label":"Results",
      "icon":"",
      "description":"N/A",
      "to":"/ghanagame/results/entry"
    },
    {
      "label":"Results Validation",
      "icon":"",
      "description":"N/A",
      "to":"/ghanagame/results/validation"
    },
    {
      "label":"Games",
      "icon":"",
      "description":"N/A",
      "to":"/settings/ghanagame/list"
    }
  ]
},
{
  "label": "Megamillion Settings",
  "icon": "SettingsIcon",
  "content":[
    {
      "label":"Megamillion Games",
      "icon":"",
      "description":"N/A",
      "to":"/settings/megamillion"
    },
    {
      "label":"Results",
      "icon":"",
      "description":"N/A",
      "to":"/megamillion/results/entry"
    },
    {
      "label":"Results Validation",
      "icon":"",
      "description":"N/A",
      "to":"/megamillion/results/validation"
    }
  ]
},
{
  "label": "JackPot Settings",
  "icon": "SettingsIcon",
  "content":[
    {
      "label":"JackPot Game",
      "icon":"",
      "description":"N/A",
      "to":"/JackPot-Games/list"
    },
    {
      "label":"JackPot Template",
      "icon":"",
      "description":"N/A",
      "to":"/JackPot-Templates/list"
    }]
  },
{
  "label": "Raffle Settings",
  "icon": "SettingsIcon",
  "content":[
    {
      "label":"Category",
      "icon":"",
      "description":"N/A",
      "to":"/raffle/category/list"
    },
    {
      "label":"Partner",
      "icon":"",
      "description":"N/A",
      "to":"/raffle/partner/list"
    },
    {
      "label":"Promoter",
      "icon":"",
      "description":"N/A",
      "to":"/raffle/promoter/list"
    },
    {
      "label":"Raffles List",
      "icon":"",
      "description":"N/A",
      "to":"/raffle/list"
    }]
  },
  {
    "label":"E-gaming",
    "icon":"SettingsIcon",
    "content":[
      {
    "label":"Egames List",
    "icon":"",
    "description":"N/A",
    "to":"/settings/egaming/list"
  }
    ]
  },
  {
    "label":"Restriction Corner",
    "icon":"NotInterestedIcon",
    "content":[
      {
    "label":"Number Control",
    "icon":"",
    "description":"N/A",
    "to":"/settings/lottery/restrictions"
  }
    ]
  },
  {
    "label": "Administration",
    "icon":"PeopleIcon",
    "content": [
    {
      "label": "Adminstration Users",
      "description": "N/A",
      "to": "/Administration/list"
    },
    {
      "label": "User roles",
      "description": "N/A",
      "to": "/administration/roles"
    }
  ]
  },
  {
    "label": "User List",
    "description": "N/A",
    "icon":"Search",
    "to": "/User/search"
  },
  {
    "label": "Online Users",
    "icon":"PersonIcon",
    "description": "N/A",
    "to": "/User/list/4"
  },
  {
    "label": "User Pending Verification",
    "icon":"VerifiedUserIcon",
    "description": "N/A",
    "to": "/User/verify"
  },
  {
    "label": "Street Users",
    "icon":"PeopleIcon",
    "content": [
      {
        "label": "Directors",
        "description": "N/A",
        "to": "/User/list/1"
      },
      {
        "label": "Managers",
        "description": "N/A",
        "to": "/User/list/6"
      },
      {
        "label": "Vande Mobil",
        "description": "N/A",
        "to": "/User/list/3"
      },
      {
        "label": "Sub Vendor",
        "description": "N/A",
        "to": "/User/list/2"
      }
    ]
  },
  {
    "label": "Account Validation",
    "icon":"VerifiedUserIcon",
    "description": "N/A",
    "content":[
      {
        "label":"Director",
        "icon":"",
        "description":"N/A",
        "to": "/Validate/1"
      },{
        "label":"Manager",
        "icon":"",
        "description":"N/A",
        "to": "/Validate/6"
      },
      {
    "label":"Vendor",
    "icon":"",
    "description":"N/A",
    "to": "/Validate/3"
  },{
    "label":"Subvendor",
    "icon":"",
    "description":"N/A",
    "to": "/Validate/2"
  }
    ]

  },
  {
    "label": "System Transactions",
    "icon": "MonetizationOnIcon",
    "display":"",
    "content":[
      {
        "label":"General Transactions",
        "icon":"",
        "description":"N/A",
        "to":"/settings/commission/general"
      },
      {
        "label":"Director's Commission",
        "icon":"",
        "description":"N/A",
        "to":"/settings/commission/director"
      }
    ]
  },
  {
    "label": "Egames Reports",
    "icon":"ReceiptIcon",
    "content" :
    [
      {
      "label":"User's Report",
      "icon":"",
      "description":"N/A",
      "to":"/Reports/Transactions/VM"
      },
      {
        "label":"Egames Ticket Report",
        "icon":"",
        "description":"N/A",
        "to":"/egames/tickets"
      }
    ]
  },
  {
    "label": "SportBook Reports",
    "icon":"ReceiptIcon",
    "content" :
    [
      {
      "label":"User's Report",
      "icon":"",
      "description":"N/A",
      "to":"/Reports/Transactions/VM"
      },
      {
        "label":"SportsBetting Ticket Report",
        "icon":"",
        "description":"N/A",
        "to":"/sportsbetting/tickets"
      }
    ]
  },
  {
    "label": "Lottery Reports",
    "icon":"ReceiptIcon",
    "content" :
    [
      {
      "label":"User's Report",
      "icon":"",
      "description":"N/A",
      "to":"/Reports/Transactions/VM"
      },
      {
        "label":"Cash Back Report",
        "icon":"",
        "description":"N/A",
        "to":"/Reports/Transactions/Cashback"
        },
        {
        "label":"Cash Back List",
        "icon":"",
        "description":"N/A",
        "to":"/Reports/Transactions/Cashbacklist"
      },
      {
        "label":"Lottery Sales Report",
        "icon":"",
        "description":"N/A",
        "to":"/Lottery/sales"
      },
      {
        "label":"Lottery Ticket Report",
        "icon":"",
        "description":"N/A",
        "to":"/Lottery/tickets"
      },
      {
        "label":"3Wa Lottery Sales Report",
        "icon":"",
        "description":"N/A",
        "to":"/Lottery/combogame/sales"
      },
      {
        "label":"3Wa Lottery Ticket Report",
        "icon":"",
        "description":"N/A",
        "to":"/Lottery/combogame/tickets"
      },
      {
        "label":"Pick2 Sales Report",
        "icon":"",
        "description":"N/A",
        "to":"/Lottery/pick2/salesreport"
      },
      {
        "label":"Pick2 Ticket Report",
        "icon":"",
        "description":"N/A",
        "to":"/Lottery/pick2/tickets"
      },
      {
        "label":"Ghana Game Sales Report",
        "icon":"",
        "description":"N/A",
        "to":"/ghanagame/sales-report"
      },
      {
        "label":"Ghana Game Tickets Report",
        "icon":"",
        "description":"N/A",
        "to":"/ghanagame/tickets"
      },
      {
        "label":"Mega Million Ticket Report",
        "icon":"",
        "description":"N/A",
        "to":"/Megamillion/list"
      },
      {
        "label":"Mega Million Sales",
        "icon":"",
        "description":"N/A",
        "to":"/Megamillion/sales-report"
      }
      ,{
      "label":"Jackpot Tickets report",
      "icon":"",
      "description":"N/A",
      "to":"/Reports/Jackpot-Tickets-Report"
    },
    {
      "label":"Jackpot Sales Reports",
      "icon":"",
      "description":"N/A",
      "to":"/Jackpot/Reports"
    },
      {
        "label":"Raffle Sales Reports",
        "icon":"",
        "description":"N/A",
        "to":"/Raffle/Reports"
      },
      {
      "label":"Raffle Tickets Reports",
      "icon":"",
      "description":"N/A",
      "to":"/Reports/Raffle-Tickets-Report"
    }
    ]
  },
  
  {
    "label":"Finance Monitoring",
    "icon":"AccountBalanceWalletIcon",
    "content":[
      {
        "label":"Debt Monitoring",
      "icon":"",
      "description":"N/A",
      "to":"/Reports/Finance/winnings"
      },
      {
        "label":"Daily Loss",
      "icon":"",
      "description":"N/A",
      "to":"/Reports/Finance/loss"
      }
    ]
  },
  {
    "label":"Live Monitoring",
    "icon":"DvrIcon",
    "content":[
      {
        "label":"Ghana Sales Monitoring",
        "to":"/ghanagame/salesmonitoring"
      },
      {
        "label":"Lottery Sales Monitoring",
        "to":"/Lottery/salesmonitoring"
      },
      {
        "label":"Pick2 Sales Monitoring",
        "to":"/Lottery/pick2salesmonitoring"
      },
      {
        "label":"End User Monitoring",
        "to":"/Lottery/endusermonitoring"
      },
      {
        "label":"Agent Monitoring",
        "to":"/Lottery/agentmonitoring"
      },
      {
        "label":"Director Monitoring",
        "to":"/Lottery/directormonitoring"
      }
    ]
  },
  {
    "label":"Marketing",
    "icon":"StoreIcon",
    "content":[
      {
        "label":"Banners",
      "icon":"",
      "description":"N/A",
      "to":"/Marketing/banner"
      },
      {
        "label":"Notifications",
        "icon":"",
        "description":"N/A",
        "to":"/Notifications"
      },
      {
        "label":"Contacts",
        "icon":"",
        "description":"N/A",
        "to":"/contacts"
      },
      {
        "label":"FAQ",
        "icon":"",
        "description":"N/A",
        "to":"/Marketing/faq"
      }
    ]
  },
  {
    "label":"BI AVP",
    "icon":"DvrIcon",
    "content":[
      {
        "label":"Sales Perfomance",
        "icon":"",
        "description":"N/A",
        "to":"/BI/salesperformance"
      },
      {
        "label":"Accounting",
        "icon":"",
        "description":"N/A",
        "to":"/Reports/Finance/accounting"
      },
      {
        "label":"User Reports",
        "icon":"",
        "description":"N/A",
        "to":"/BI/user-reports"
      },
      {
        "label":"User Engagement",
        "icon":"",
        "description":"N/A",
        "to":"/BI/user-engagement"
      },
      {
        "label":"Egaming Report",
        "icon":"",
        "description":"N/A",
        "to":"/BI/egaming-report"
      },
      {
        "label":"Vendor Section",
        "icon":"PeopleIcon",
        "content":[
          {
            "label":"Financial",
            "icon":"",
            "description":"N/A",
            "to":"/BI/vendor-financial"
          }

        ]
      }
    ]
  },
  {
    "label":"DTS Plan",
    "icon":"Phone",
    "description":"N/A",
    "to":"/dtsplan/list"
  },
  {
    "label":"AVP versioning",
    "icon":"UpdateIcon",
    "description":"N/A",
    "to":"/app/versioning"
  },
  {
    "label":"App Settings",
    "icon":"SettingsIcon",
    "description":"N/A",
    "to":"/app/settings"
  }
]`,
      (key, value) => {
        if (key === 'icon') {
          return iconsMap[value];
        } else {
          return value;
        }
      }
    )
  }
];


/*
  {
    "label": "Overview",
    "icon": "DashboardTwoToneIcon",
    "to": "/Overview"
  },
       


  reports----------------
  {
        "label":"Raffle Reports",
        "icon":"",
        "description":"N/A",
        "to":"/Raffle/Reports"
      },
      {   
      "label":"Raffle Tickets Reports",
      "icon":"",
      "description":"N/A",
      "to":"/Reports/Raffle-Tickets-Report"
    },
    {
      "label":"Jackpot Tickets report",
      "icon":"",
      "description":"N/A",
      "to":"/Reports/Jackpot-Tickets-Report"
    },
    {
      "label":"Jackpot Reports",
      "icon":"",
      "description":"N/A",
      "to":"/Jackpot/Reports"
    },
      {
        "label":"Mega Million",
        "icon":"",
        "description":"N/A",
        "to":"/Megamillion/list"
      }



      ///sportbook sales report

            {
        "label":"SportsBetting sales Report",
        "icon":"",
        "description":"N/A",
        "to":"/sportsbetting/salesreport"
      }
*/