import React, { Fragment } from 'react';
//import { createTheme } from '@material-ui/core/styles';

import { Grid, CircularProgress } from '@material-ui/core';


export default function LivePreviewExample() {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    function tick() {
      // reset when reaching 100%
      setProgress(oldProgress => (oldProgress >= 100 ? 0 : oldProgress + 1));
    }

    const timer = setInterval(tick, 50);
    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <Fragment>
      <Grid
        item
        sm={6}
        className="d-flex align-items-center justify-content-center">
        <div className="m-3">
          <CircularProgress color={'#ff9800'} />
        </div>

      </Grid>
    </Fragment>
  );
}
