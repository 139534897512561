import React, { lazy, Fragment, Suspense } from 'react';
import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import AdministrationLoginModel from './models/AdministrationLoginModel';
import { GlobalAuthContext } from './contexts/GlobalContext';
import { AnimatePresence } from 'framer-motion';
import jwtDecode from 'jwt-decode';
import { ThemeProvider } from '@material-ui/styles';

import MuiTheme from './theme';
import ProgressBarsCircular from './components/ProgressBars/ProgressBarsCircular';
// Layout Blueprints

import { LeftSidebar, PresentationLayout } from './layout-blueprints';

// Example Pages

import Buttons from './pages/Buttons';
import Dropdowns from './pages/Dropdowns';
import NavigationMenus from './pages/NavigationMenus';
import ProgressBars from './pages/ProgressBars';
import Pagination from './pages/Pagination';
import Scrollable from './pages/Scrollable';
import Badges from './pages/Badges';
import Icons from './pages/Icons';
import UtilitiesHelpers from './pages/UtilitiesHelpers';
import RegularTables1 from './pages/RegularTables1';
import RegularTables4 from './pages/RegularTables4';
import FormsControls from './pages/FormsControls';
import AvpProgress from 'components/ProgressBars/AvpProgress';
import axios from 'axios';
import { BASE_URL } from 'utils/config';

const Login = lazy(() => import('./pages/Login'));
const DashboardDefault = lazy(() => import('./pages/DashboardDefault'));
const Overview = lazy(() => import('./pages/Overview'));
const ApkVersioning = lazy(() => import('./pages/ApkVersioning'))
const ApkSettings = lazy(()=> import('./pages/ApkSettings'))
const ManageUsers = lazy(() => import('./pages/ManageUsers'));
const ManageRoles = lazy(()=>import('./pages/ManageRoles'))
const VandeMobil = lazy(() => import('./pages/ManageVendor'));
const ManageVendor = lazy(() => import('./pages/ManageNvendor'));

const Players = lazy(() => import('./pages/Players'));
const ManageCommission = lazy(() => import('./pages/ManageCommission'));
const DirectorCommission = lazy(() => import('./pages/DirectorCommission'))
const UserCommission = lazy(() => import('./pages/UserCommission'));
const Reports = lazy(() => import('./pages/Reports'));
const FinancialHealth = lazy(() => import('./pages/FinancialHealth'));
const AdsPromo = lazy(() => import('./pages/AdsPromo'));
const Cards3 = lazy(() => import('./pages/Cards3'));
const Accordions = lazy(() => import('./pages/Accordions'));
const Modals = lazy(() => import('./pages/Modals'));
const Notifications = lazy(() => import('./pages/Notifications'));
const Popovers = lazy(() => import('./pages/Popovers'));
const Tabs = lazy(() => import('./pages/Tabs'));
const ApexCharts = lazy(() => import('./pages/ApexCharts'));
const Maps = lazy(() => import('./pages/Maps'));
const ListGroups = lazy(() => import('./pages/ListGroups'));
const TransmiteForm = lazy(() => import('./pages/TransmiteForm'));
const LotteryResults = lazy(() => import('./pages/LotteryResults'));
const LotteryResultsTable = lazy(()=>import('./pages/LotteryResultsTable'));
const LotteryResultsValidation = lazy(() => import('./pages/LotteryResultsValidation'))

//Megamillion game parameters components
const MegamillionResults = lazy(() => import('./pages/MegamillionResults'))
const MegamillionResultsValidation = lazy(() => import('./pages/MegamillionResultsValidation'))
const MegamillionParameters = lazy(()=>import('./pages/MegamillionParameters'));
const MegamillionGame = lazy(()=>import('./pages/MegamillionGame'))
const MegaMillion = lazy(() => import('./pages/MegaMillion'));

//Pick game views
const LotteryPicks = lazy(() => import('./pages/SalesMonitoring'));
const PickGameListing = lazy(()=>import('./pages/PickGameListing'))
const PickResults = lazy(()=>import('./pages/PickGameResults'))
const PickTicketReport = lazy(()=>import('./pages/PickTickets'))
const PickSalesReport = lazy(()=>import('./pages/PickGamesSalesReport'))
const PickResultsValidation = lazy(()=>import('./pages/PickResultValidation'))
const PickGameSalesMonitoring = lazy(()=>import('./pages/PickSalesMonitoring'))

//3 Wa game views
const ComboTicketReport = lazy(()=>import('./pages/ComboLotteryTicket'));
const ComboGameListing = lazy(()=>import('./pages/ComboGameListing'))
const ComboSalesReport = lazy(()=>import('./pages/ComboGameSalesReport'))
const ComboLotteryResultsValidation = lazy(() => import('./pages/ComboLotteryResultsValidation'))

//Egames View
const EgamesTicketReportView = lazy(()=>import('./pages/EgamesTickeReport'))

const WinningTicket = lazy(() => import('./pages/WinningTicket'));
const SalesByLottery = lazy(() => import('./pages/SalesByLottery'));
const AgentMonitoring = lazy(() => import('./pages/AgentMonitoring'));
const EnduserMonitoring = lazy(() => import('./pages/EndUserMonitoring'));
const VerifyUsers = lazy(() => import('./pages/VerifyUser'));
const ValidateVendors = lazy(() => import('./pages/VendorValidation'));
const ContactApp = lazy(()=>import('./pages/ContactApp'))
const DirectorMonitoring = lazy(() =>
  import('./pages/DirectorMonitoring')
);
const TransactionReportVm = lazy(() =>
  import('./pages/TransactionReportVm')
);
const DtsPlan = lazy(()=>import('./pages/DtsPlan'))
const UserList = lazy(()=>import('./pages/UserHistory'))
const FinanceWinnings = lazy(() => import('./pages/FinanceMonitoring'));
const DailyLoss = lazy(() => import('./pages/DailyLoss'));
const VendorMReport = lazy(() => import('./pages/UserProfile'));
const GameListing = lazy(() => import('./pages/GameListing'));

const LotteryRestrictions = lazy(() =>
  import('./pages/LotteryRestrictions')
);
const LotteryParameters = lazy(() =>
  import('./pages/LotteryParameters')
);
const LotteryUserParameters = lazy(() =>
  import('./pages/LotteryUserParameters'));
const SalesReport = lazy(() => import('./pages/SalesReport'));
const VandeNasyonalForm = lazy(() =>
  import('./pages/VandeNasyonalForm')
);
const ManagerForm = lazy(() => import('./pages/ManagerForm'))
//const MostSoldNumbers = lazy(()=> import('./example-pages/MostSoldNumbers'));
const SoldTicket = lazy(() => import('./pages/SoldTicket/index'));
const VandeMobilForm = lazy(() => import('./pages/VandeMobilForm'));
const Marketing = lazy(() => import('./pages/Marketing'))
const FrequentlyAskedQuestions = lazy(() => import('./pages/Faq/index'))
const Partner = lazy(() => import('./pages/Partner'));
const RegisterPartner= lazy(() => import('./pages/RegisterPartner'));
const Promoter = lazy(() => import('./pages/Promoter'));
const RegisterPromoter= lazy(() => import('./pages/RegisterPromoter'));

//Raffle Views
const Raffle = lazy(() => import('./pages/Raffle'));
const RafflePartnerList = lazy(() => import('./pages/RafflePartnerList'));
const RafflePromoterList = lazy(() => import('./pages/RafflePromoterList'));
const CreateRaffle = lazy(() => import('./pages/CreateRaffle'));
const RaffleReport = lazy(()=>import('./pages/RaffleReport'));
const RaffleTicketsReport = lazy(()=>import('./pages/RaffleTicketsReport'))
const RaffleCategoryList = lazy(()=>import('./pages/RaffleCategoryList'))
const RegisterCategory = lazy(()=>import('./pages/RegisterCategory'))
const RaffleTicketDetails = lazy(()=>import('./pages/RaffleTicketsDetails'));
const RaffleTickets = lazy(()=>import('./pages/RaffleTickets'))

//Jackpot Views
const CreateJackpot = lazy(()=>import('./pages/CreateJackpot'));
const JackPotList = lazy(()=>import('./pages/JackPotList'))
const JackPotTemplate =lazy(()=>import ('./pages/JackPotTemplate'));
const JackPotTicketsReport =  lazy(()=>import('./pages/JackPotTicketsReport'))
const RegisterJackpotTemplate= lazy(()=>import('./pages/RegisterJackpotTemplate'));
const JackpotReport = lazy(()=>import('./pages/JackpotReport'));
const JackpotTickets = lazy(()=>import('./pages/JackpotTickets'))
const JackpotTicketDetails =lazy(()=>import('./pages/JackpotTicketsDetails'));

//BI
const SalesPerformance = lazy(() => import('./pages/SalesPerformance'));
const UserReports = lazy(() => import('./pages/UserReports'));
const UserEngagement = lazy(() => import('./pages/UserEngagement'));
const VendorFinancial = lazy(()=> import('./pages/VendorFinancial'));
const AccountingReport = lazy(()=> import('./pages/AccountingReport'));
const EgamingReport = lazy(()=> import('./pages/EgamingReport'));

//Megamillion views
const MegamillionSalesReport = lazy(()=>import('./pages/MegamillionSalesReport'))

//Ghana games views
const GhanaGhameTicketReport = lazy(()=>import('./pages/GhanaGameTickets'))
const GhanaSalesReport = lazy(()=>import('./pages/GhanaSalesReport'))
const GhanaGameParameters = lazy(()=>import('./pages/GhanaGame'))
const GhanaGameResults = lazy(()=>import('./pages/GhanaGameResults'))
const GhanaGameResultTable = lazy(()=>import('./pages/GhanaGameResultTable'))
const GhanaGameResultsValidation = lazy(()=>import('./pages/GhanaGameResultsValidation'))
const GhanaGameSalesMonitoring = lazy(()=>import('./pages/GhanaSalesMonitoring'))

//Egaming views
const EgamingList = lazy(()=>import('./pages/EgamingTable'))


//SportBook views
const SportsbookTicketReportView = lazy(()=>import('./pages/SportsBookTickets'))


//CRM Views
const FollowUpListView = lazy(()=>import('./pages/FollowUpList'))


//Cash back views
const CashBackReportsView = lazy(()=>import('./pages/CashBacks'))
const CashBackList = lazy(()=>import('./pages/CashBackList'))

const Routes = () => {



  const location = useLocation();
  const history = useHistory();
  const [expired, setExpired] = React.useState(false);
  const { state, action } = React.useContext(GlobalAuthContext);
  //isConnected, token, infouser
  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
  };
  const verifyToken = ()=>{
    axios({
      method:'GET',
      url:`${BASE_URL}/auth-admin`,
      headers:{
        'Content-type': 'application/json',
        Authorization: `Bearer ${state.token}`
      }

    }).then(res=>{
       setExpired(false);

    }).catch(error=>{
      let response = error.request.response;
      let code = JSON.parse(response).code;

      if(code === 4031 || code === 4033) {
        setExpired(true)

      }
    })
}


verifyToken();



  React.useEffect(()=>{
    // handleSession();
  },[state])


  if (state.isLoading) {
    return (
      <AvpProgress />
    );
  }

  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense
          fallback={

            <AvpProgress />

          }>
          <Fragment>
            {state.isConnected &&
              state.token != null &&
              state.infouser != null && expired === false ? (
              <Switch>

                <Redirect exact from="/Login" to={'/Lottery/tickets'}></Redirect>
                <Redirect exact from="/" to="/Lottery/tickets"></Redirect>

                <Route
                  path={[
                    '/Overview',
                    '/Administration/list',
                    '/administration/roles',
                    '/VandeNasyonal/list/:typeuser',
                    '/User/list/:typeuser',
                    '/Transmite/list/:typeuser',
                    '/Players/list/:typeuser',
                    '/ManageCommission',
                    '/Raffle/Reports',
                    '/Raffle-tickets/:id',
                    '/LotteryManaging',
                    '/dtsplan/list',
                    '/Reports',
                    '/FinancialHealth',
                    '/AdsPromo',
                    '/DashboardDefault',
                    '/app/settings',
                    '/Buttons',
                    '/Dropdowns',
                    '/NavigationMenus',
                    '/ProgressBars',
                    '/Pagination',
                    '/Scrollable',
                    '/Badges',
                    '/Icons',
                    '/UtilitiesHelpers',
                    '/Cards3',
                    '/Accordions',
                    '/Modals',
                    '/Popovers',
                    '/Tabs',
                    '/RegularTables1',
                    '/RegularTables4',
                    '/FormsControls',
                    '/ApexCharts',
                    '/Maps',
                    '/ListGroups',
                    '/Notifications',
                    '/Register/Subvendor/:refeuser_id',
                    '/Register/VandeMobil',
                    '/Register/Director',
                    '/Register/Manager',
                    '/JackPot-Games/list',
                    '/JackPot-Templates/list',
                    '/Reports/JackPot-Tickets-Report',
                    '/egames/tickets',
                    '/egames/salesreport',
                    '/ghanagame/results/entry',
                    '/ghanagame/sales-report',
                    '/ghanagame/results/validation',
                    '/ghanagame/salesmonitoring',
                    '/ghanagame/tickets',
                    '/sportsbetting/tickets',
                    '/sportsbetting/salesreport',
                    '/Lottery/agentmonitoring',
                    '/Lottery/directormonitoring',
                    '/lottery/endusermonitoring',
                    '/Lottery/results/entry',
                    '/Lottery/results/listing',
                    '/Lottery/results/validation',
                    '/Lottery/pick2/results/entry',
                    '/Lottery/pick2/results/listing',
                    '/Lottery/pick2/salesreport',
                    '/Lottery/pick2/tickets',
                    '/Lottery/pick2/results/validation',
                    '/Lottery/combogame/sales',
                    '/Lottery/combogame/tickets',
                    '/Lottery/combolottery/results/validation',
                    '/Lottery/pick2/results/validation',
                    '/Lottery/pick2salesmonitoring',
                    '/megamillion/results/entry',
                    '/megamillion/results/validation',
                    '/Megamillion/sales-report',
                    '/Lottery/salesmonitoring',
                    '/Lottery/tickets',
                    '/Lottery/winningtickets',
                    '/Lottery/sales',
                    '/Lottery/prize',
                    '/Marketing/banner',
                    '/Marketing/broadcast',
                    '/Marketing/faq',
                    '/Megamillion/list',
                    '/Raffle/Category/list',
                    '/Raffle/category/new',
                    '/Raffle/partner/list',
                    '/Raffle/promoter/list',
                    '/Raffle/partner/new',
                    '/Raffle/promoter/new',
                    '/Raffle/list',
                    '/Raffle/new',
                    '/Raffle/ticket/details/:id',
                    '/Jackpot/ticket/details/:id',
                    '/Jackpot-tickets/:id',
                    '/Register/jackpot-template',
                    '/Register/jackpot-games',
                    '/Settings/lottery/parameters',
                    '/Settings/lottery/user/parameters',
                    '/Settings/lottery/restrictions',
                    '/Settings/lottery/pick2/games',
                    '/Settings/lottery/reports',
                    '/Settings/lottery/games',
                    '/Settings/lottery/combolottery/games',
                    '/Settings/commission/general',
                    '/Settings/commission/director',
                    '/Settings/commission/usercommission',
                    '/settings/ghanagame/list',
                    '/settings/megamillion',
                    '/settings/megamillion/list/:id',
                    '/settings/egaming/list',
                    '/Reports/Transactions/VM',
                    '/Reports/Transactions/Enduser',
                    '/Reports/Transactions/Representative',
                    '/Reports/Transactions/Central',
                    '/Reports/Transactions/Cashback',
                    '/Reports/Raffle-Tickets-Report',
                    '/Reports/JackPot-Tickets-Report',
                    '/Reports/Transactions/Cashbacklist',
                    '/Jackpot/Reports',
                    '/Reports/user/:id',
                    '/Reports/game/:id',
                    '/app/versioning',
                    '/contacts',
                    '/User/search',
                    '/User/search/:phone',
                    '/User/verify',
                    '/users/followup',
                    '/Validate/:typeuser',
                    '/BI/salesperformance',
                    '/BI/user-reports',
                    '/BI/user-engagement',
                    '/BI/egaming-report',
                    '/BI/vendor-financial',
                    '/Reports/Finance/accounting'
                  ]}>
                  <LeftSidebar>
                    <Switch location={location} key={location.pathname}>
                      <motion.div
                        initial="initial"
                        animate="in"
                        exit="out"
                        variants={pageVariants}
                        transition={pageTransition}>
                        <Route path="/Overview" component={Overview} />
                        <Route path="/AdsPromo" component={AdsPromo} />
                        <Route
                          path="/VandeNasyonal/list/:typeuser"
                          component={ManageVendor}
                        />
                        <Route
                          path="/User/list/:typeuser"
                          component={VandeMobil}
                        />
                        <Route
                          path="/app/settings"
                          component={ApkSettings}
                        />
                        <Route
                          path="/dtsplan/list"
                          component={DtsPlan}
                        />
                        <Route
                          path="/Administration/list"
                          component={ManageUsers}
                        />
                        <Route
                          path="/administration/roles"
                          component={ManageRoles}
                        />
                        <Route
                          path="/Transmite/list/:typeuser"
                          component={ManageVendor}
                        />
                        <Route
                          path="/Players/list/:typeuser"
                          component={Players}
                        />
                        <Route 
                          path="/egames/tickets"
                          component={EgamesTicketReportView }
                        />
                        <Route 
                          path="/sportsbetting/tickets"
                          component={SportsbookTicketReportView}
                        />
                        <Route
                          path="/Settings/commission/general"
                          component={ManageCommission}
                        />
                        <Route
                          path="/Settings/commission/director"
                          component={DirectorCommission}
                        />
                        <Route path="/Reports" component={Reports} />
                        <Route
                          path="/FinancialHealth"
                          component={FinancialHealth}
                        />
                        <Route
                          path="/DashboardDefault"
                          component={DashboardDefault}
                        />
                        <Route path="/Buttons" component={Buttons} />
                        <Route path="/Dropdowns" component={Dropdowns} />
                        <Route
                          path="/NavigationMenus"
                          component={NavigationMenus}
                        />
                        <Route path="/ProgressBars" component={ProgressBars} />
                        <Route path="/Pagination" component={Pagination} />
                        <Route path="/Scrollable" component={Scrollable} />
                        <Route path="/Badges" component={Badges} />
                        <Route path="/Icons" component={Icons} />
                        <Route
                          path="/UtilitiesHelpers"
                          component={UtilitiesHelpers}
                        />
                        <Route path="/Cards3" component={Cards3} />
                        <Route path="/Accordions" component={Accordions} />
                        <Route path="/Modals" component={Modals} />
                        <Route path="/Popovers" component={Popovers} />
                        <Route path="/Tabs" component={Tabs} />
                        <Route
                          path="/RegularTables1"
                          component={RegularTables1}
                        />
                        <Route
                          path="/RegularTables4"
                          component={RegularTables4}
                        />
                        <Route
                          path="/FormsControls"
                          component={FormsControls}
                        />
                        <Route path="/ApexCharts" component={ApexCharts} />
                        <Route path="/Maps" component={Maps} />
                        <Route path="/ListGroups" component={ListGroups} />
                        <Route
                          path="/register/subvendor/:refeuser_id"
                          component={TransmiteForm}
                        />
                        <Route
                          path="/register/director"
                          component={VandeNasyonalForm}
                        />
                        <Route
                          path="/register/manager"
                          component={ManagerForm}
                        />
                        <Route
                          path="/register/vandeMobil"
                          component={VandeMobilForm}
                        />
                        <Route
                          path="/ghanagame/results/entry"
                          component={GhanaGameResultTable}
                        />
                        <Route
                          path="/ghanagame/sales-report"
                          component={GhanaSalesReport}
                        />
                        <Route
                          path="/ghanagame/results/validation"
                          component={GhanaGameResultsValidation}
                        />
                        <Route
                          path="/ghanagame/salesmonitoring"
                          component={GhanaGameSalesMonitoring}
                        />
                        <Route
                          path="/ghanagame/tickets"
                          component={GhanaGhameTicketReport}
                        />
                        <Route
                          path="/settings/egaming/list"
                          component={EgamingList}
                        />
                        <Route
                          path="/settings/ghanagame/list"
                          component={GhanaGameParameters}
                        />
                        <Route
                          path="/lottery/results/entry"
                          component={LotteryResults}
                        />
                        <Route
                          path="/lottery/results/validation"
                          component={LotteryResultsValidation}
                        />
                        <Route
                          path="/Lottery/results/listing"
                          component={LotteryResultsTable}
                        />
                        <Route
                          path="/settings/lottery/pick2/games"
                          component={PickGameListing}
                        />
                        <Route
                          path="/lottery/pick2/results/entry"
                          component={PickResults}
                        />
                        <Route
                          path="/lottery/pick2/results/validation"
                          component={PickResultsValidation}
                        />
                        <Route
                          path="/lottery/pick2/tickets"
                          component={PickTicketReport}
                        />
                        <Route
                          path="/Lottery/pick2salesmonitoring"
                          component={PickGameSalesMonitoring}
                        />

                        <Route
                          path="/lottery/pick2/salesreport"
                          component={PickSalesReport}
                        />
                        <Route
                          path="/Lottery/combogame/sales"
                          component={ComboSalesReport}
                        />
                        <Route
                          path="/Lottery/combolottery/results/validation"
                          component={ComboLotteryResultsValidation}
                        />
                        <Route
                          path="/settings/lottery/combolottery/games"
                          component={ComboGameListing}
                        />
                        <Route
                          path="/Lottery/combogame/tickets"
                          component={ComboTicketReport}
                        />
                        <Route
                          path="/settings/megamillion/list/:id"
                          component={MegamillionGame}
                        />
                        <Route
                          path="/settings/megamillion"
                          component={MegamillionParameters}
                        />
                        {/* MegamillionParameter */}
                        <Route
                          path="/megamillion/results/entry"
                          component={MegamillionResults}
                        />
                        <Route
                          path="/megamillion/results/validation"
                          component={MegamillionResultsValidation}
                        />
                        <Route
                          path="/megamillion/sales-report"
                          component={MegamillionSalesReport}
                        />
                        <Route
                          path={'/Raffle/category/new'}
                          component={RegisterCategory}
                          />
                        <Route
                          path={'/Raffle/category/list'}
                          component={RaffleCategoryList}
                          />
                        <Route
                          path="/Raffle/partner/list"
                          component={RafflePartnerList}
                        />
                        <Route
                          path="/Raffle/partner/new"
                          component={RegisterPartner}
                        />
                         <Route
                          path="/Raffle/ticket/details/:id"
                          component={RaffleTicketDetails}
                        />
                        <Route
                          path="/Raffle-tickets/:id"
                          component={RaffleTickets}
                        />
                        <Route
                          path="/Jackpot/ticket/details/:id"
                          component={JackpotTicketDetails}
                        />
                        <Route
                          path="/Jackpot-tickets/:id"
                          component={JackpotTickets}
                        />
                         <Route
                          path="/JackPot-Games/list"
                          component={JackPotList}
                        />
                        <Route
                          path='/Register/jackpot-games'
                          component={CreateJackpot}
                        />
                        <Route
                        path="/JackPot-Templates/list"
                        component={JackPotTemplate}
                        />
                       <Route
                          path="/Reports/Jackpot-Tickets-Report"
                          component={JackPotTicketsReport}
                        />
                        <Route
                          path="/Raffle/promoter/list"
                          component={RafflePromoterList}
                        />
                        <Route
                          path="/Raffle/promoter/new"
                          component={RegisterPromoter}
                        />
                        <Route
                        path="/Reports/Raffle-Tickets-Report"
                        component={RaffleTicketsReport}
                        />
                        <Route
                          path="/Raffle/list"
                          component={Raffle}
                        />
                        <Route
                          path="/Raffle/new"
                          component={CreateRaffle}
                        />
                        <Route
                          path="/Raffle/Reports"
                          component={RaffleReport}
                        />
                        <Route
                          path="/Jackpot/Reports"
                          component={JackpotReport }
                          />
                        <Route
                        path="/Register/jackpot-template"
                        component={RegisterJackpotTemplate}
                        />
                        <Route
                          path="/Notifications"
                          component={Notifications}
                        />
                        <Route
                          path="/Megamillion/list"
                          component={MegaMillion}
                        />
                        <Route
                          path="/lottery/salesmonitoring"
                          component={LotteryPicks}
                        />
                        <Route path="/lottery/tickets" component={SoldTicket} />
                        <Route
                          path="/lottery/winningtickets"
                          component={WinningTicket}
                        />
                        <Route
                          path="/lottery/sales"
                          component={SalesByLottery}
                        />
                        <Route
                          path="/lottery/agentmonitoring"
                          component={AgentMonitoring}
                        />
                        <Route
                          path="/lottery/endusermonitoring"
                          component={EnduserMonitoring}
                        />
                        <Route
                          path="/lottery/directormonitoring"
                          component={DirectorMonitoring}
                        />
                        <Route
                          path="/Marketing/banner"
                          component={Marketing}
                        />
                        <Route
                          path="/Marketing/broadcast"
                          component={null}
                        />
                        <Route
                          path="/Marketing/faq"
                          component={FrequentlyAskedQuestions}
                        />
                        <Route
                          path="/settings/lottery/parameters"
                          component={LotteryParameters}
                        />
                        <Route
                          path="/settings/lottery/user/parameters"
                          component={LotteryUserParameters}
                        />
                        <Route
                          path="/settings/lottery/restrictions"
                          component={LotteryRestrictions}
                        />
                        <Route
                          path="/settings/lottery/games"
                          component={GameListing}
                        />

                        <Route
                          path="/Reports/Transactions/VM"
                          component={TransactionReportVm}
                        />
                        <Route
                          path="/Reports/Transactions/Cashback"
                          component={CashBackReportsView}
                        />
                        <Route
                          path="Reports/Transactions/cashbacklist"
                          component={CashBackReportsView}
                        />
                        <Route
                          path="/Reports/Finance/winnings"
                          component={FinanceWinnings}
                        />
                        <Route
                          path="/Reports/user/:id"
                          component={VendorMReport}
                        />
                        <Route
                          path="/Reports/game/:id"
                          component={SalesReport}
                        />
                        <Route
                          path="/Reports/Finance/loss"
                          component={DailyLoss}
                        />
                        <Route
                          path="/Reports/Finance/accounting"
                          component={AccountingReport}
                        />
                        <Route
                          path="/app/versioning"
                          component={ApkVersioning}
                        />
                        <Route
                          path="/User/verify"
                          component={VerifyUsers}
                        />
                        <Route
                          path="/Validate/:typeuser"
                          component={ValidateVendors}
                        />
                        <Route
                        path="/User/search"
                        component={UserList}
                        />
                        <Route
                          path="/BI/salesperformance"
                          component={SalesPerformance}
                        />
                        <Route
                          path="/BI/user-reports"
                          component={UserReports}
                        />
                        <Route
                          path="/BI/user-engagement"
                          component={UserEngagement}
                        />

                        <Route
                          path="/BI/vendor-financial"
                          component={VendorFinancial}
                        />
                        <Route
                          path="/BI/egaming-report"
                          component={EgamingReport}
                        />

                        <Route
                          path="/contacts"
                          component={ContactApp}
                        />
                        <Route
                          path="/users/followup"
                          component={FollowUpListView}
                        />
                        

                      </motion.div>
                    </Switch>
                  </LeftSidebar>
                </Route>
              </Switch>
            ) : (
              <Switch>
                <Route path={['/Login']}>
                  <PresentationLayout>
                    <Switch location={location} key={location.pathname}>
                      <motion.div
                        initial="initial"
                        animate="in"
                        exit="out"
                        variants={pageVariants}
                        transition={pageTransition}>
                        <Route exact path="/Login" component={Login} />
                      </motion.div>
                    </Switch>
                  </PresentationLayout>
                </Route>
                <Redirect to="/Login"></Redirect>
              </Switch>
            )}
          </Fragment>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  );
};

export default Routes;