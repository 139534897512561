const AdministrationLoginModel = function(data) {
  this.login_at = new Date();
  this.token = data?.token || null;
  this.success = data?.success || null;

  this.administration = {
    email: data?.administration?.email || '',
    first_name: data?.administration?.first_name || '',
    last_name: data?.administration?.last_name || '',
    telephone: data?.administration?.telephone || '',
    birthday: data?.administration?.birthday || null,
    created_at: data?.administration?.created_at || null,
    updated_at: data?.administration?.updated_at || null,

    role_id: data?.administration?.role_id || null,
    id: data?.administration?.id || null,
    isBlocked: data?.administration?.isBlocked
  };

  this.roles = {
    name: data?.administration?.role?.name || '',
    level: data?.administration?.role?.level || null,
    id: data?.administration?.role?.id || null
  };

  this.error = data?.error;
};

export default AdministrationLoginModel;
