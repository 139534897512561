import React, { Fragment } from 'react';

import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { IconButton, Box } from '@material-ui/core';

import projectLogo from '../../assets/images/stock-logos/avplogo.svg';

const HeaderLogo = props => {
  return (
    <Fragment>
      <div className={clsx('app-header-logo', {})}>
        <Box className="header-logo-wrapper" title="Avantaj Pam">
          <Link to="/Overview" className="header-logo-wrapper-link">
            <IconButton size="medium" className="">
              <img
                className="app-header-logo-img"
                alt="Avantaj Pam"
                src={projectLogo}
              />
            </IconButton>
          </Link>
          <Box className="header-logo-text">Avantaj Pam</Box>
        </Box>
      </div>
    </Fragment>
  );
};

export default HeaderLogo;
