import React, { createContext } from 'react';
//Model
import AdministrationLoginModel from '../models/AdministrationLoginModel';
export const GlobalAuthContext = createContext();

const GlobalAuthContextProvider = props => {
  const [state, dispatch] = React.useReducer(
    (prevState, action) => {
      switch (action.type) {
        case 'RETRIEVE_TOKEN':
          return {
            ...prevState,
            isConnected: action.isConnected || false,
            token: action.token,
            infouser: action.infouser,
            isLoading: false
          };
        case 'LOGIN':
          return {
            ...prevState,
            isConnected: action.isConnected || false,
            token: action.token,
            infouser: action.infouser,
            isLoading: false
          };
        case 'LOGOUT':
          return {
            ...prevState,
            isConnected: false,
            token: null,
            infouser: new AdministrationLoginModel(),
            isLoading: false
          };
        default:
          return {
            ...prevState,
            isConnected: false,
            token: null,
            infouser: new AdministrationLoginModel(),
            isLoading: true
          };
      }
    },
    {
      isConnected: false,
      token: null,
      infouser: new AdministrationLoginModel(),
      isLoading: true
    }
  );

  React.useEffect(() => {
    // Fetch the token from storage then navigate to our appropriate place
    const bootstrapAsync = async () => {
      let newAdmLogin = {};
      try {
        newAdmLogin = JSON.parse(
          window.localStorage.getItem('credentials') || {}
        );
      } catch (error) {
        newAdmLogin = {};
      }

      dispatch({
        type: 'RETRIEVE_TOKEN',
        isConnected: newAdmLogin.token ? true : false,
        token: newAdmLogin.token,
        infouser: newAdmLogin
      });

      return false;
    };

    bootstrapAsync();
  }, []);

  const authContext = React.useMemo(
    () => ({
      logInHandler: async data => {
        try {
          let newAdmLogin = new AdministrationLoginModel(data || null);
          window.localStorage.setItem(
            'credentials',
            JSON.stringify(newAdmLogin)
          );
          dispatch({
            type: 'LOGIN',
            token: newAdmLogin.token,
            infouser: newAdmLogin,
            isConnected: newAdmLogin.token ? true : false
          });
          return true;
        } catch (error) {
          return false;
        }
      },
      logoutHandler: async () => {
        window.localStorage.clear();
        dispatch({ type: 'LOGOUT' });
      }
    }),
    []
  );

  return (
    <GlobalAuthContext.Provider value={{ action: authContext, state }}>
      {props.children}
    </GlobalAuthContext.Provider>
  );
};

export default GlobalAuthContextProvider;
