import React, { Fragment, useState, useContext, useEffect } from 'react'
import { Grid,IconButton, FormControl, TextField, Box} from '@material-ui/core'
import { Search } from '@material-ui/icons'
import { LinearProgress } from '@material-ui/core';
import { Link, useHistory, useLocation} from 'react-router-dom';

export default function HeaderSearch() {
    const history = useHistory();
    const location= useLocation();
    const [searchUser, setSearchUser] = useState('');
    const actualRoute = location.pathname;
    const handleChange = e => {
        const { name, value } = e.target;
        setSearchUser(value);
      };
    
      const handleSubmit = e => {
        e.preventDefault();
        console.log(location)
        if(actualRoute.slice(0,11)==='/User/search')
        {
            console.log(true)
            history.go(0)
        }else{
          history.push({
            pathname: `/User/search`,
            search: `phone=${searchUser}`,  // query string
            state: {  // location state
              phone: searchUser, 
            },
          }); 
      };  
        }
        
    return (
        <Box className='d-flex align-items-center flex-row'>
                <FormControl
                    fullWidth
                    xs={6}
                    sm={6}
                    className={'d-flex align-items-center flex-row'}
                    style={{
                        backgroundColor:"#FFF",
                        borderRadius:10


                    }}
                >
                    <TextField
                        id="query"
                        
                        type={'text'}
                        value={searchUser}
                        name="query"
                        onChange={handleChange}
                        InputLabelProps={{
                            shrink: true
                        }}
                        disabled={false}
                        variant="standard"
 InputProps={{
        disableUnderline: true,
                            name: 'query',
                            id: 'query',
                            style: { padding: 12, border:'none' }
                        }}
                    />

                <IconButton
                style={{
                    borderRadius:50
                }}
              onClick={handleSubmit}
              variant="contained"
              className='p-2 ml-3'
              color="primary"
              elevation={0}>
              <Search className='ml-2' />
            </IconButton>
</FormControl>
            </Box>
        
        
    )
}