import React, { Fragment } from 'react';

import { Paper } from '@material-ui/core';

import FavoriteIcon from '@material-ui/icons/Favorite';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from 'react-router-dom';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import SaveIcon from '@material-ui/icons/Save';
import PrintIcon from '@material-ui/icons/Print';
import ShareIcon from '@material-ui/icons/Share';
import BackIcon from '@material-ui/icons/ArrowBack';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SettingsIcon  from '@material-ui/icons/SettingsOutlined';

const actions = [
  { icon: <FileCopyIcon />, name: 'Copy' },
  { icon: <SaveIcon />, name: 'Save' },
  { icon: <PrintIcon />, name: 'Print' },
  { icon: <ShareIcon />, name: 'Share' },
  { icon: <FavoriteIcon />, name: 'Like' }
];

function PageTitle(props) {
  const [open, setOpen] = React.useState(false);
  const history = useHistory();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <Paper square elevation={2} className="app-page-title">
        <div>
          <div className="app-page-title--first">
            <div className="app-page-title--heading">
              <h1>{props.titleHeading}</h1>
              <div className="app-page-title--description">
                {props.titleDescription}
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center">
          {props.childComponent ? (
            <div className="speedial-wrapper">
              <SpeedDial
                className="align-self-center"
                ariaLabel="SpeedDial menu"
                icon={<BackIcon />}
                onClick={() => history.go}
                onClick={() => history.goBack()}></SpeedDial>
            </div>
          ) : <SpeedDial className="align-self-center"
          ariaLabel="SpeedDial menu"
          icon={<SettingsIcon/>}></SpeedDial>}
        </div>
      </Paper>
    </Fragment>
  );
}

export default PageTitle;
