import BarChartIcon from '@material-ui/icons/BarChart';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ChatIcon from '@material-ui/icons/ChatOutlined';
import CodeIcon from '@material-ui/icons/Code';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import DashboardTwoToneIcon from '@material-ui/icons/DashboardTwoTone';
import GradeTwoTone from '@material-ui/icons/GradeTwoTone';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import MailIcon from '@material-ui/icons/MailOutlined';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import ViewModuleIcon from '@material-ui/icons/ViewModule';

var iconsMap = {
  BarChartIcon: BarChartIcon,
  CalendarTodayIcon: CalendarTodayIcon,
  ChatIcon: ChatIcon,
  CodeIcon: CodeIcon,
  DashboardIcon: DashboardIcon,
  ErrorIcon: ErrorIcon,
  FolderIcon: FolderIcon,
  DashboardTwoToneIcon: DashboardTwoToneIcon,
  GradeTwoTone: GradeTwoTone,
  ListAltIcon: ListAltIcon,
  LockOpenIcon: LockOpenIcon,
  MailIcon: MailIcon,
  PresentToAllIcon: PresentToAllIcon,
  PeopleIcon: PeopleIcon,
  PersonIcon: PersonIcon,
  ReceiptIcon: ReceiptIcon,
  SettingsIcon: SettingsIcon,
  ViewModuleIcon: ViewModuleIcon
};

export default [
  {
    label: 'Menu',
    content: JSON.parse(
      `[
  {
    "label": "Lottery Settings",
    "icon": "SettingsIcon",
    "content":[
      {
        "label":"Results",
        "icon":"",
        "description":"N/A",
        "to":"/Lottery/results/entry"
      },
      {
        "label":"Results Validation",
        "icon":"",
        "description":"N/A",
        "to":"/Lottery/results/validation"
      } 
    ]
  },
  {
    "label": "Pick 2 Settings",
    "icon": "SettingsIcon",
    "content":[
      {
        "label":"Results",
        "icon":"",
        "description":"N/A",
        "to":"/Lottery/pick2/results/entry"
      },
      {
        "label":"Results Validation",
        "icon":"",
        "description":"N/A",
        "to":"/Lottery/pick2/results/validation"
      }
    ]
  },
  {
    "label": "Ghana Game Settings",
    "icon": "SettingsIcon",
    "content":[
      {
        "label":"Results",
        "icon":"",
        "description":"N/A",
        "to":"/ghanagame/results/entry"
      },
      {
        "label":"Results Validation",
        "icon":"",
        "description":"N/A",
        "to":"/ghanagame/results/validation"
      },
      {
        "label":"Games",
        "icon":"",
        "description":"N/A",
        "to":"/settings/ghanagame/list"
      }
    ]
  },
  {
    "label": "3Wa Settings",
    "icon": "SettingsIcon",
    "content":[
      {
        "label":"3Wa Games",
        "icon":"",
        "description":"N/A",
        "to":"/settings/lottery/combolottery/games"
      },
      {
        "label":"3Wa Results Validation",
        "icon":"",
        "description":"N/A",
        "to":"/Lottery/combolottery/results/validation"
      }
    ]
  }, 
  {
    "label": "Megamillion Settings",
    "icon": "SettingsIcon",
    "content":[
      {
        "label":"Results",
        "icon":"",
        "description":"N/A",
        "to":"/megamillion/results/entry"
      },
      {
        "label":"Results Validation",
        "icon":"",
        "description":"N/A",
        "to":"/megamillion/results/validation"
      }]
  }
]`,
      (key, value) => {
        if (key === 'icon') {
          return iconsMap[value];
        } else {
          return value;
        }
      }
    )
  }
];
