import BarChartIcon from '@material-ui/icons/BarChart';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ChatIcon from '@material-ui/icons/ChatOutlined';
import { Phone,Search } from '@material-ui/icons';
import CodeIcon from '@material-ui/icons/Code';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import DashboardTwoToneIcon from '@material-ui/icons/DashboardTwoTone';
import GradeTwoTone from '@material-ui/icons/GradeTwoTone';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import HistoryIcon from '@material-ui/icons/History';
import MailIcon from '@material-ui/icons/MailOutlined';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import DvrIcon from '@material-ui/icons/Dvr';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import SendIcon from '@material-ui/icons/Send';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import StoreIcon from '@material-ui/icons/Store';
import BurstModeIcon from '@material-ui/icons/BurstMode';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import UpdateIcon from '@material-ui/icons/Update';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
var iconsMap = {
  BarChartIcon: BarChartIcon,
  CalendarTodayIcon: CalendarTodayIcon,
  Phone: Phone,
  ChatIcon: ChatIcon,
  HistoryIcon :HistoryIcon ,
  CodeIcon: CodeIcon,
  Search:Search,
  DashboardIcon: DashboardIcon,
  FolderIcon: FolderIcon,
  DashboardTwoToneIcon: DashboardTwoToneIcon,
  GradeTwoTone: GradeTwoTone,
  ListAltIcon: ListAltIcon,
  LockOpenIcon: LockOpenIcon,
  MailIcon: MailIcon,
  PresentToAllIcon: PresentToAllIcon,
  PeopleIcon: PeopleIcon,
  PersonIcon: PersonIcon,
  ReceiptIcon: ReceiptIcon,
  SettingsIcon: SettingsIcon,
  ViewModuleIcon: ViewModuleIcon,
  MonetizationOnIcon: MonetizationOnIcon,
  DesktopWindowsIcon: DesktopWindowsIcon,
  DvrIcon: DvrIcon,
  AccountBalanceWalletIcon: AccountBalanceWalletIcon,
  SendIcon: SendIcon,
  TrendingDownIcon: TrendingDownIcon,
  AttachMoneyIcon: AttachMoneyIcon,
  StoreIcon: StoreIcon,
  BurstModeIcon: BurstModeIcon,
  LiveHelpIcon: LiveHelpIcon,
  PhoneIphoneIcon: PhoneIphoneIcon,
  UpdateIcon: UpdateIcon,
  NotInterestedIcon: NotInterestedIcon,
  VerifiedUserIcon: VerifiedUserIcon
};

export default [
  {
    label: 'MENU',
    content: JSON.parse(
      `[
  {
    "label":"Marketing",
    "icon":"StoreIcon",
    "content":[
      {
        "label":"Banners",
      "icon":"",
      "description":"N/A",
      "to":"/Marketing/banner"
      },
      {
        "label":"Notifications",
        "icon":"",
        "description":"N/A",
        "to":"/Notifications"
      },
      {
        "label":"Contacts",
        "icon":"",
        "description":"N/A",
        "to":"/contacts"
      },
      {
        "label":"FAQ",
        "icon":"",
        "description":"N/A",
        "to":"/Marketing/faq"
      }
    ]
  }
]`,
      (key, value) => {
        if (key === 'icon') {
          return iconsMap[value];
        } else {
          return value;
        }
      }
    )
  }
];


/*
  {
    "label": "Overview",
    "icon": "DashboardTwoToneIcon",
    "to": "/Overview"
  },
       


  reports----------------
  {
        "label":"Raffle Reports",
        "icon":"",
        "description":"N/A",
        "to":"/Raffle/Reports"
      },
      {   
      "label":"Raffle Tickets Reports",
      "icon":"",
      "description":"N/A",
      "to":"/Reports/Raffle-Tickets-Report"
    },
    {
      "label":"Jackpot Tickets report",
      "icon":"",
      "description":"N/A",
      "to":"/Reports/Jackpot-Tickets-Report"
    },
    {
      "label":"Jackpot Reports",
      "icon":"",
      "description":"N/A",
      "to":"/Jackpot/Reports"
    },
      {
        "label":"Mega Million",
        "icon":"",
        "description":"N/A",
        "to":"/Megamillion/list"
      }
*/