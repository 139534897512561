import React, { Component } from 'react';

export default class ExampleWrapperSeamless extends Component {
  render() {
    return (
      <div style={{
        // borderRadius: 10, shadowColor: '#000',
        // shadowOffset: { width: 0, height: 2 },
        // shadowOpacity: .1,
        // shadowRadius: 8,
        // elevation: 2,
      }} className="example-card-seamless mb-4-spacing">
        <h5 className="display-5 mb-4 font-weight-bold " style={{ textAlign: `${this.props.textAlign}` }}>
          {this.props.sectionHeading}
        </h5>
        <div>{this.props.children}</div>
      </div>
    );
  }
}
